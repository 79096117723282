<template>
    <form action='https://crm.zoho.com/crm/WebToLeadForm' name=WebToLeads5133313000003012001 method='POST' accept-charset='UTF-8' class="main-form" data-inview="fadeInUp" data-delay="400">
        <input type='text' style='display:none;' name='xnQsjsdp' value='7c79b6fad9259539944b421168c82afab429e0a27149a5d8e29db619b5b70b33'/>
        <input type='hidden' name='zc_gad' id='zc_gad' value=''/>
        <input type='text' style='display:none;' name='xmIwtLD' value='c24abe1f9193724d9156084082735497696544774fef9c41cfe63589e36ba9d3'/>
        <input type='text'  style='display:none;' name='actionType' value='TGVhZHM='/>
        <!-- <input type='text' style='display:none;' name='returnURL' value='https//lomboi.ca/contact?success=1' /> -->

        <!-- Utm -->
        <input type="hidden" id='LEADCF10' name='LEADCF10' value="Web">

        <!-- Source -->
        <input type="hidden" id='LEADCF9' name='LEADCF9' value="n/a">
        <!-- Medium -->
        <input type="hidden" id='LEADCF11' name='LEADCF11' value="n/a">
        <!-- Campagne -->
        <input type="hidden" id='LEADCF8' name='LEADCF8' value="n/a">

        <!-- Langue -->
        <input type="hidden" id='LEADCF5' name='LEADCF5' :value="$route.meta.lang == 'fr' ? 'Français' : 'Anglais'">
        <!-- Projet -->
        <input type="hidden" id='LEADCF4' name='LEADCF4' value="Lomboi 1">

        <h2 class="regular-title">
            <span v-html="formInfos.titre"></span>
        </h2>

        <div class="form-message" v-if="formMessage">
            <p class="form-message-text" :class="formMessageStatus">
                {{ formMessage }}
            </p>
        </div>

        <div class="inputs-row">
            <div class="input">
                <label for="">{{ $t('Prénom') }} *</label>
                <input type="text" :placeholder="$t('Prénom') + ' *'" id='First_Name' name='First Name' maxlength='40' required />
            </div>
            <div class="input">
                <label for="">{{ $t('Nom') }} *</label>
                <input type="text" :placeholder="$t('Nom') + ' *'" id='Last_Name' name='Last Name' maxlength='80' required />
            </div>
        </div>

        <div class="inputs-row">
            <div class="input">
                <label for="">{{ $t('Courriel') }} *</label>
                <input type="text" :placeholder="$t('Courriel') + ' *'" ftype='email' id='Email' name='Email' maxlength='100' required />
            </div>
            <div class="input">
                <label for="">{{ $t('Téléphone') }} *</label>
                <input type="text" :placeholder="$t('Téléphone') + ' *'" id='Phone' name='Phone' maxlength='30' required />
            </div>
        </div>

        <div class="inputs-row">
            <div class="input">
                <label for="">{{ $t('Nombre de chambres') }}</label>
                <select id='LEADCF6' name='LEADCF6' :placeholder="$t('Nombre de chambres')">
                    <option value="-None-">{{ $t('Nombre de chambres') }}</option>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                </select>
            </div>
            <div class="input">
                <label for="">{{ $t("Source d'information") }}</label>
                <select id='Lead_Source' name='Lead Source' :placeholder="'Source'">
                    <option value='-None-'>{{ $t("Source d'information") }}</option>
                    <option value='Médias Sociaux'>{{ $t('Médias Sociaux') }}</option>
                    <option value='Annonces Facebook / Instagram'>{{ $t('Annonces Facebook / Instagram') }}</option>
                    <option value='Annonces Google Ads'>{{ $t('Annonces Google Ads') }}</option>
                    <option value='Recherche Google'>{{ $t('Recherche Google') }}</option>
                    <option value='Centris'>{{ $t('Centris') }}</option>
                    <option value='Guide Habitation'>{{ $t('Guide Habitation') }}</option>
                    <option value='Affichage extérieur'>{{ $t('Affichage extérieur') }}</option>
                    <option value='Référence amis ou famille'>{{ $t('Référence amis ou famille') }}</option>
                    <option value='Autres'>{{ $t('Autres') }}</option>
                </select>
            </div>
        </div>

        <div class="inputs-row">
            <div class="full">
                <label for="">Message</label>
                <textarea id='LEADCF1' name='LEADCF1' placeholder="Message"></textarea>
            </div>
        </div>

        <div class="inputs-row ender">
            <div class="left">
                <p class="small-text">
                    {{ formInfos.texteLegal }}
                    <br />
                    <strong>* {{ $t('champs obligatoires') }}</strong>
                </p>
            </div>
            <div class="right">
                <button class="site-btn" type="submit" id='formsubmit'>
                    <span>{{ $t('Envoyer') }}</span>
                    <svg
                        height="14"
                        viewBox="0 0 25 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M21.0759 10.2231L15.5559 16.3111L15.3031 16.5899L15.566 16.8592L15.9762 17.2794L16.2732 17.5836L16.5588 17.2687L23.3596 9.76869L23.6032 9.5L23.3596 9.23131L16.5588 1.73131L16.2625 1.40452L15.9661 1.73131L15.5559 2.1837L15.3117 2.45302L15.5566 2.72177L21.073 8.77694L1.51376 8.77694L1.11376 8.77694L1.11376 9.17694L1.11376 9.82306L1.11376 10.2231L1.51376 10.2231L21.0759 10.2231Z"
                            fill="black"
                            stroke="black"
                            stroke-width="0.8"
                        />
                    </svg>

                    <svg
                        class="hover-arrow"
                        height="14"
                        viewBox="0 0 25 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M21.0759 10.2231L15.5559 16.3111L15.3031 16.5899L15.566 16.8592L15.9762 17.2794L16.2732 17.5836L16.5588 17.2687L23.3596 9.76869L23.6032 9.5L23.3596 9.23131L16.5588 1.73131L16.2625 1.40452L15.9661 1.73131L15.5559 2.1837L15.3117 2.45302L15.5566 2.72177L21.073 8.77694L1.51376 8.77694L1.11376 8.77694L1.11376 9.17694L1.11376 9.82306L1.11376 10.2231L1.51376 10.2231L21.0759 10.2231Z"
                            fill="#fff"
                            stroke="#fff"
                            stroke-width="0.8"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Form',

    data() {
        return {
            formMessage: '',
            formMessageStatus: '',
        }
    },

    watch: {
        $route: {
            handler($route) {
                if ($route) {
                    setTimeout(() => {
                        // If success query is set, show success message
                        if (this.$route.query.success && parseInt(this.$route.query.success)) {
                            this.formMessage = this.$t('Votre message a été envoyé avec succès.')
                            this.formMessageStatus = 'success'
                        }

                        // If utm_source is present
                        if (this.$route.query.utm_source || localStorage.getItem('lomboi_utm_source')) {
                            const utm_source = this.$route.query.utm_source || localStorage.getItem('lomboi_utm_source')
                            document.getElementById('LEADCF9').value = utm_source
                            localStorage.setItem('lomboi_utm_source', utm_source)
                        }

                        // If utm_medium is present
                        if (this.$route.query.utm_medium || localStorage.getItem('lomboi_utm_medium')) {
                            const utm_medium = this.$route.query.utm_medium || localStorage.getItem('lomboi_utm_medium')
                            document.getElementById('LEADCF11').value = utm_medium
                            localStorage.setItem('lomboi_utm_medium', utm_medium)
                        }

                        // If utm_campaign is present
                        if (this.$route.query.utm_campaign || localStorage.getItem('lomboi_utm_campaign')) {
                            const utm_campaign = this.$route.query.utm_campaign || localStorage.getItem('lomboi_utm_campaign')
                            document.getElementById('LEADCF8').value = utm_campaign
                            localStorage.setItem('lomboi_utm_campaign', utm_campaign)
                        }
                    }, 155)
                }
            },
            immediate: true,
        },
    },

    computed: {
        formInfos() {
            if (!this.globalsIsLoaded || !this.$route) {
                return {}
            }

            let indexOfForm = 'formulaireInscription'

            if (this.$route.name.includes('Projet')) {
                indexOfForm = 'formulaireVariantePageProjet'
            }

            if (this.$route.name.includes('Contact')) {
                indexOfForm = 'formulaireVariantePageContact'
            }

            return this.globals.footer[indexOfForm]
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded', 'initAnimsDelay1', 'initAnimsDelay2']),
        ...mapGetters(['globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
