<template>
    <div id="contact" class="page-wrapper page-contact">
        <section class="contact-wrap site-max-width hero-transition-group">
            <img v-if="globalsIsLoaded" class="main" :src="globals.footer.formulaireVariantePageContact.lienImage.url" :alt="globals.footer.formulaireVariantePageContact.lienImage.titre" />

            <div class="left">
                <Form />
            </div>
            <div class="right" v-if="globalsIsLoaded">
                <h3 class="regular-title title" data-inview="fadeInUp" data-delay="200">
                    {{ $t('Informations') }}
                </h3>
                <p class="regular-text text" data-inview="fadeInUp" data-delay="600">
                    {{ globals.contact.informationsDeContact.heuresDouvertures }}
                </p>

                <a
                    :href="'tel:' + globals.contact.informationsDeContact.telephone"
                    class="regular-text link"
                    data-inview="fadeInUp"
                    data-delay="800"
                >
                    <svg
                        height="24"
                        viewBox="0 0 25 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20.6688 41.5229H3.57308C1.60209 41.5229 0 39.919 0 37.9498V3.57308C0 1.60209 1.60387 0 3.57308 0H20.6688C22.6398 0 24.2419 1.60209 24.2419 3.57308L24.2437 37.9498C24.2437 39.9208 22.6398 41.5229 20.6688 41.5229H20.6688ZM3.5749 1.42567C2.39157 1.42567 1.4275 2.38979 1.4275 3.57308L1.42571 37.9498C1.42571 39.1349 2.38983 40.0972 3.57312 40.0972H20.6689C21.854 40.0972 22.8163 39.1331 22.8163 37.9498L22.8181 3.57308C22.8181 2.38797 21.8539 1.42567 20.6707 1.42567H3.5749Z"
                            fill="black"
                        />
                        <path
                            d="M23.5319 6.34279H0.713813C0.319971 6.34279 0.000976562 6.02379 0.000976562 5.62995C0.000976562 5.23611 0.319971 4.91711 0.713813 4.91711H23.53C23.9239 4.91711 24.2429 5.23611 24.2429 5.62995C24.2447 6.02379 23.9257 6.34279 23.5318 6.34279H23.5319Z"
                            fill="black"
                        />
                        <path
                            d="M23.5319 36.6058H0.713813C0.319971 36.6058 0.000976562 36.2868 0.000976562 35.893C0.000976562 35.4991 0.319971 35.1801 0.713813 35.1801H23.53C23.9239 35.1801 24.2429 35.4991 24.2429 35.893C24.2447 36.2868 23.9257 36.6058 23.5318 36.6058H23.5319Z"
                            fill="black"
                        />
                        <path
                            d="M15.3175 3.88328H8.92866C8.53482 3.88328 8.21582 3.56429 8.21582 3.17045C8.21582 2.77661 8.53482 2.45761 8.92866 2.45761H15.3175C15.7113 2.45761 16.0303 2.77661 16.0303 3.17045C16.0303 3.56429 15.7113 3.88328 15.3175 3.88328Z"
                            fill="black"
                        />
                        <path
                            d="M13.7283 39.0652H10.5117C10.1178 39.0652 9.79883 38.7462 9.79883 38.3524C9.79883 37.9586 10.1178 37.6396 10.5117 37.6396H13.7283C14.1222 37.6396 14.4412 37.9586 14.4412 38.3524C14.4412 38.7462 14.1222 39.0652 13.7283 39.0652Z"
                            fill="black"
                        />
                    </svg>
                    {{ globals.contact.informationsDeContact.telephone }}
                </a>
                <a
                    :href="'mailto:' + globals.contact.informationsDeContact.courriel"
                    class="regular-text link"
                    data-inview="fadeInUp"
                    data-delay="1000"
                >
                    <svg
                        height="12"
                        viewBox="0 0 35 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M34.8821 3.33349C34.7665 2.77524 34.5338 2.24623 34.1985 1.78398C33.3717 0.651147 32.0501 -0.0112715 30.6488 0.000145198H4.35048C2.93938 0.000145198 1.61616 0.68373 0.800756 1.83282C0.471987 2.28203 0.239225 2.79149 0.117185 3.33349C0.0406909 3.66715 0.00162914 4.00732 0 4.35074V20.6512V20.6496C0 23.0519 1.94821 25 4.35034 25H30.6486C33.0509 25 34.9989 23.0517 34.9989 20.6496V4.35078C35.0071 4.00735 34.968 3.66557 34.8818 3.33353L34.8821 3.33349ZM4.35052 1.66682H30.6488C31.4724 1.66356 32.2487 2.04605 32.75 2.70036L17.8999 11.5496C17.6557 11.683 17.3595 11.683 17.1154 11.5496L2.24898 2.70036C2.75026 2.04607 3.52662 1.66357 4.35019 1.66682H4.35052ZM33.3329 20.6498C33.3329 22.1325 32.1318 23.3337 30.649 23.3337H4.35078C2.86805 23.3337 1.66691 22.1325 1.66691 20.6498V4.30057L16.2825 13.0002C17.0425 13.4348 17.9735 13.4348 18.7336 13.0002L33.3329 4.30057V20.6498Z"
                            fill="black"
                        />
                    </svg>
                    {{ globals.contact.informationsDeContact.courriel }}
                </a>
            </div>
        </section>

        <div class="spacer"></div>
    </div>
</template>

<script>
// import axios from 'axios'
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import Form from '@/components/Form'

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: 'Contact',

    components: {
        Form,
    },

    data() {
        return {
            formMessage: '',
            formMessageStatus: '',
            isSending: false,
            formData: {
                nom: '',
                prenom: '',
                telephone: '',
                courriel: '',
                heures: '',
                message: '',
            },
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.startHomeHeroAnimations()
                        // this.initZoomAnimations()

                        setTimeout(() => {
                            initInViewAnimations()
                            document.querySelector('#app').classList.add('is-loaded')
                            document.querySelector('.main').classList.add('is-active')
                        }, this.initAnimsDelay1)
                    }, 475)

                    setTimeout(() => {
                        document.querySelector('.site-loader').classList.add('is-loaded')
                        document.querySelector('.page-wrapper').classList.add('is-loaded')
                        this.setAnimsDelays(55)
                    }, this.initAnimsDelay2)
                }
            },
            immediate: true,
        },
    },

    mounted() {},

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded', 'initAnimsDelay1', 'initAnimsDelay2']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        ...mapActions(['setAnimsDelays']),
    },
}
</script>
